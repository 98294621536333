import React from 'react'
import style from './index.module.less'
import {
    looking_1,
    looking_2,
    looking_3
}from '@/images/pioneers/index'
const LookingFor = () =>{
    const list = [
        {
            title:"Authentic Storytellers",
            desc:"Individuals who can convey compelling narratives about their experiences with XREAL products.",
            img:looking_1
        },  {
            title:"XREAL Gurus",
            desc:"Experts who stay current with all things XREAL, AR, and emerging tech.",
            img:looking_2
        },  {
            title:"Passionate Contributors",
            desc:"Those who love sharing their knowledge and personality through various forms of content, from posts and comments to videos and reviews.",
            img:looking_3
        }
    ]
    return(
        <div className={style.box}>
            <div className={style.title}>We are Looking for</div>
            <div className={style.content}>
                {
                    list.map((item,index)=>(
                        <div className={style.contentItem} key={`pioneers_looking_${index}`}>
                            <div className={style.contentItemImg}
                                style={{backgroundImage:`url(${item.img})`}}
                            ></div>
                            <div className={style.contentItemTitle}>
                                {item.title}
                            </div>
                            <div className={style.contentItemDesc}>
                                {item.desc}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default LookingFor