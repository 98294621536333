import React from 'react'
import style from './index.module.less'
import {
    shouldbe_svg_1,
    shouldbe_svg_2,
    shouldbe_svg_3,
    shouldbe_svg_4,
    shouldbe_svg_5
} from '@/images/pioneers/index'
const ShouldBe = () => {
    const list = [
        {
            title: "Create Valuable Content",
            desc: "Produce at least one piece of content per month. This could be a video, written post, or any format that best suits your platform.",
            img:shouldbe_svg_1
        },
        {
            title: "Participate in Promotion",
            desc: "Occasionally contribute to product promotions and special events.",
            img:shouldbe_svg_2
        }, {
            title: "Authorize Your Content",
            desc: "Grant XREAL the right to repost or otherwise edit your content for commercial use and promotion.",
            img:shouldbe_svg_3
        }, {
            title: "Support the Brand",
            desc: "Highlight your endorsement of XREAL on influential platforms.",
            img:shouldbe_svg_4
        }, {
            title: "Boost Your Social Presence",
            desc: "Include the hashtag <span>#XREALPioneers</span> or tag our official account <span>@XREAL_Global</span> when sharing your content.",
            img:shouldbe_svg_5
        }
    ]
    return (
        <div className={style.box}>
           {list.map((item,index)=>(
            <div className={style.listItem} key={`pioneers_shouldBe_${index}`}>
                <img src={item.img} />
                <div className={style.title}>
                    {item.title}
                </div>
                <div className={style.desc} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}></div>
            </div>
           ))}
        </div>
    )
}

export default ShouldBe