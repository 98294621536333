import React from 'react'
import style from './index.module.less'
import {
    perks_svg1,
    perks_svg2,
    perks_svg3,
    perks_svg4,
    perks_svg5,
    perks_svg6
} from '@/images/pioneers/index'
const Perks = () => {
    const list = [
        {
            title: "Honorary Title",
            desc: "XREAL Pioneers are brand ambassadors on the front lines. Your name will be featured on an exclusive page on our website, showcasing your contributions to the XREAL Community.",
            img: perks_svg1
        },
        {
            title: "Product Testing",
            desc: "Gain the opportunity to test the latest products before they official launch! Represent XREAL online and in the real world with our AR devices. <br/>Get direct access to the XREAL development and communications teams.",
            img: perks_svg2
        }, {
            title: "Exposure Support",
            desc: "When you publish high-quality content, XREAL will support you by sharing your posts, engaging with your channel(s), and even promoting your content through our official channels.",
            img: perks_svg3
        }, {
            title: "Event Invitations",
            desc: "As an XREAL Pioneer, you will be invited to XREAL-organized events such as community meet-ups, trade shows, and product launches, with the potential in some instances for travel support provided by XREAL.",
            img: perks_svg4
        }, {
            title: "XREAL Brand Package",
            desc: "As an XREAL Pioneer, you may receive exclusive XREAL brand ambassador swag.",
            img: perks_svg5
        }, {
            title: "Potential Collaboration",
            desc: "You may be invited to participate in our affiliate program, offering the opportunity to earn a commission on sales generated through your direct recommendations.",
            img: perks_svg6
        }
    ]
    return (
        <div className={style.box}>
            {list.map((item, index) => (
                <div className={style.listItem} key={`pioneers_perks_${index}`}>
                    <img src={item.img} />
                    <div className={style.title}>
                        {item.title}
                    </div>
                    <div className={style.desc} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}
                    >
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Perks